import Link from 'gatsby-plugin-transition-link'
import React from "react"

export default props => (
  <nav class="header__nav">
    <ul>
      <li>
        <Link
          to="/"
          className={props.selectedLink === `1` ? `active` : ``}
        >
          Half Marathon
        </Link>
      </li>
      <li>
        <Link
          to="/10m"
          className={props.selectedLink === `7` ? `active` : ``}
        >
          10 Mile
        </Link>
      </li>
      <li>
        <Link
          direction="right"
          to="/10k"
          className={props.selectedLink === `4` ? `active` : ``}
        >
          10K
        </Link>
      </li>
      <li>
        <Link
          direction="right"
          to="/40m"
          className={props.selectedLink === `8` ? `active` : ``}
        >
          40 Mile
        </Link>
      </li>
      <li>
        <Link
          direction="right"
          to="/tralee-50k-ultra-marathon"
          className={props.selectedLink === `5` ? `active` : ``}
        >
          50K
        </Link>
      </li>
      <li>
        <Link
          direction="right"
          to="/tralee-100k-ultra-marathon"
          className={props.selectedLink === `6` ? `active` : ``}
        >
          100K
        </Link>
      </li>

    </ul>
  </nav>
)
