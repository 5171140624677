
import PropTypes from "prop-types"
import React from "react"

import logo from "../images/logo-tralee-marathon.png"
import Nav from "../components/nav"

// Define component's JSX
const Header = ({ siteTitle, selectedLink }) => (
  <div className="header__holder">
    <header>
      <div className="header__inside">
        <div className="header__logo">
          <img src={logo} alt={siteTitle} />
        </div>
        <Nav selectedLink={selectedLink} />
      </div>
    </header>
  </div>
)

// Define propTypes for validation
Header.propTypes = {
  siteTitle: PropTypes.string,
  selectedLink: PropTypes.string,
}

// Define default props
Header.defaultProps = {
  siteTitle: `Tralee Marathon 2020`,
  selectedLink: ``,
  logo: `../images/logo-tralee-marathon.png`
}

// Export component
export default Header
